<template>
  <div class="box">
    <div class="content" v-if="false">
      <div class="left">
        <div class="icon">
          <img src="../assets/logo_btm.png" width="100%" height="100%" />
        </div>
        <div class="address_content">
          <div class="address">
            公司地址：浙江省金华市义乌市北苑街道雪峰西路968号
          </div>
          <div class="tel">
            <div>电话：0579-83815373</div>
            <div>电子邮箱：RCEES_ywcsjzx@163.com</div>
          </div>
        </div>
      </div>
      <div class="right">
        <img src="../assets/3.png" />
        <div class="title">关注我们</div>
      </div>
    </div>

    <div class="youlian">
      <div style="width:150px;">
        友情链接：</div>
      <div class="left_btn" @click="scrollLeft">
        <img src="../assets/home/left.png" alt="">
      </div>
      <div class="monitor-list">
        <!-- 中间列表 -->
        <div id="list-box" class="list-box">
          <div id="list" class="list">
            <div v-for="item in monitorList" :key="item.id" class="list-item">
              <a target="_blank" :href="item.url">{{ item.name }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="right_btn" @click="scrollRight">
        <img src="../assets/home/right.png" alt="">
      </div>
    </div>
    <div class="content1">
      <div class="left_part">

        <div class="home">
          <div v-for="(item, index) in list" :key="index" @click="goNvr(item.url)">{{ item.name }}</div>
          <!-- <div>网站首页</div>
          <div>中心介绍</div>
          <div>新闻资讯</div>
          <div>技术成果</div>
          <div>交易平台</div>
          <div>人才招聘</div>
          <div>联系我们</div> -->
        </div>
        <div class="introduce">
          <div>
            <img src="../assets/home/address.png" />
            <div class="address">
              公司地址：浙江省金华市义乌市北苑街道雪峰西路968号
            </div>
          </div>

          <div>
            <img src="../assets/home/phone.png" />
            <div>电话：0579-83815373</div>
          </div>

          <div>
            <img src="../assets/home/email.png" />
            <div>电子邮箱：RCEES_ywcsjzx@163.com</div>
          </div>

        </div>

      </div>
      <div class="right_part">

        <!-- <img src="../assets/3.png" /> -->
        <img src="../assets/public.jpg" />

        <div class="title">关注我们</div>
      </div>

    </div>
    <div class="bom">
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"> 浙ICP备2021039390号-2 &nbsp;&nbsp;|&nbsp;&nbsp;</a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078202001982"
          style="display:inline-block;text-decoration:none;color:rgba($color: #ffffff, $alpha: 0.6);">
          <img src="../assets/tabga.png"  />
          <p style="float:right;margin: 0px 0px 0px 5px; color:#a3b5e0;">浙公网安备
            33078202001982号</p>
        </a>
        技术支持：<a target="_blank" href="http://9czn.cn/">杭州九宸智能科技有限公司</a>
      
    </div>
  </div>
</template>

<script>
import { menuList, listPain } from "../api/api";

export default {
  data() {
    return {
      list: [
        {
          name: '网站首页',
          url: '/index'
        },
        {
          name: '中心介绍',
          url: '/centerIntroduced'
        },
        {
          name: '新闻资讯',
          url: '/news'
        },
        {
          name: '技术成果',
          url: '/generalProfile'
        },
        {
          name: '交易平台',
          url: '/tradingPlatform'
        },
        {
          name: '人才招聘',
          url: '/recruitment'
        },
        {
          name: '联系我们',
          url: '/contactUsNew'
        },
      ],
      friendshipList: [],
      linkpid: '',

      monitorList: [],
      imgList: {
        alive: require('@/assets/home/left.png'),
        down: require('@/assets/home/right.png')
      }
    };
  },
  mounted() {
    this.menuList()
  },
  methods: {
    goNvr(e) {
      if (e == this.$route.path) return
      this.$router.push({
        path: e,
      });
    },
    async menuList() {
      let res = await menuList();
      if (res.ResultCode == 0) {
        this.linkpid = res.Data[0].childs[0].pid;
      }
      // 友情链接
      if (this.linkpid) {
        let linkPains = await listPain({ typeidSelsource: this.linkpid });
        if (linkPains.ResultCode == 0) {
          let obj = JSON.parse(linkPains.Data.Items[0].other);
          this.friendshipList = obj;
          this.initMonitorList()
          // }
        }
      }
    },
    initMonitorList() {
      console.log(this.friendshipList, 1231);
      for (let i = 0; i < this.friendshipList.length; i++) {
        this.monitorList.push({
          id: i,
          name: this.friendshipList[i].name,
          url: this.friendshipList[i].url,
          status: 0
        })
      }
    },
    // 左滑动逻辑
    scrollLeft() {
      const allLength = this.monitorList.length * 277
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength - 360 < boxLength) {
        // 到最开始的时候
        listEl.style.left = '0px'
      } else {
        listEl.style.left = '-' + (leftMove - 360) + 'px'
      }
    },
    // 右滑动逻辑
    scrollRight() {
      const allLength = this.monitorList.length * 250
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength + 350 > allLength) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 360) + 'px'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.monitor-list {
  width: 74rem;
  overflow: hidden;

  .list-box {

    .list {
      width: calc(100vw - 100px);
      display: flex;
      transform: all 2s;
      align-items: center;
      // float: left;

      .list-item {
        text-align: center;
        cursor: pointer;
        padding-right: 3rem;

        a {

          width: 100%;
          color: #fff;
        }
      }

      position: relative;
      left: 0;
      transition: left 1s;
    }
  }
}

.box {
  background: #2250C0;

  .youlian {
    // width: 75%;
    width: 93.75rem;
    margin: auto;
    height: 6.25rem;
    color: #fff;
    display: flex;
    align-items: center;

    .right_btn,
    .left_btn {
      margin: 0 1.25rem;
    }

    .name {
      width: 31.25rem;
      overflow: hidden;

      .nameList {
        width: 31.25rem;
        display: flex;

        &>div {
          text-align: center;
          // background-color: aqua;
          height: 1.25rem;
          margin: 0 .625rem;

        }
      }
    }

    img {
      width: 10px;
      height: 20px;
    }
  }

  .content1 {
    // width: 75%;
    width: 93.75rem;
    margin: auto;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    text-align: center;
    margin-bottom: 50px;

    .left_part {
      .home {
        display: grid;
        grid-template-columns: 10% 10% 10% 10% 10% 10% 10%;
        margin: 20px 0;
        cursor: pointer;
        text-align: left;
      }

      .introduce {
        color: rgba(255, 255, 255, 0.5);
        display: grid;
        grid-template-columns: 50% 30% 50%;

        &>div {
          display: flex;
          text-align: left;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }

    .right_part {
      img {
        width: 87px;
        height: 87px;
      }

      // margin-right: 6.25rem;
    }

    // display: grid;
    //   grid-template-columns: 25% 25% 25% 25%;
    .home {
      display: flex;
    }
  }

  .content {
    background: #2250C0;
    // height: 23.5625rem;
    width: 80%;
    margin: 0 auto;
    padding-top: .0625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      margin-top: 6.6875rem;
      display: flex;

      .icon {
        width: 13.625rem;
        height: 3.25rem;
      }

      .address_content {
        font-size: .875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba($color: #ffffff, $alpha: 0.8);
        margin-left: 4.5rem;

        .tel {
          display: flex;
          margin-top: .625rem;

          div {
            &:nth-child(2) {
              margin-left: 1.25rem;
            }
          }
        }
      }
    }

    .right {
      margin-top: 3.9375rem;
      text-align: center;

      .title {
        margin-top: .9375rem;
        font-size: .75rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.9375rem;
        opacity: 0.5;
      }
    }
  }

  .bom {
    height: 4.375rem;
    background: #1845B2;
    line-height: 4.375rem;
    text-align: center;
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba($color: #ffffff, $alpha: 0.6);

    a {
      color: rgba($color: #ffffff, $alpha: 0.6);
      p{
        
      color: rgba($color: #ffffff, $alpha: 0.6);
      }
    }
  }
}
</style>

<style lang="scss">
@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 980px) {
  .left_part {
    .home {
      display: grid;
      grid-template-columns: 10% 10% 10% 10% \n 10% 10% 10%;
      // background-color: #fff;
    }

    .introduce {
      display: flex !important;
      flex-direction: column;
      // background-color: #fff;
    }
  }
}


@media only screen and (max-width: 640px) {

  .left_part {
    .home {
      display: flex;
      flex-direction: column;
    }

    .introduce {
      display: flex;
      flex-direction: column;
    }

  }


}
</style>